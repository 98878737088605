<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <div style="display: flex;align-content: center;justify-content: space-between">
          <div>
            <vxe-button type="submit" icon="el-icon-plus" size="mini" @click="goPage('add')"/>
            <vxe-button
              type="submit"
              icon="el-icon-upload2"
              status="primary"
              @click="dataupload()"
            >统计导出
            </vxe-button>

            <vxe-button
              type="submit"
              icon="el-icon-upload2"
              status="primary"
              @click="upload()"
            >导出
            </vxe-button>
            <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
            <vxe-button
              v-if="!seachStatus"
              status="my-orange"
              icon="fa fa-search"
              @click="showSearch(true)"
            >查询
            </vxe-button>
          </div>
          <div>
            <vxe-button
              type="submit"
              icon="el-icon-upload2"
              status="primary"
              @click="upload2()"
            >图片导出
            </vxe-button>

            <vxe-button
              type="submit"
              icon="el-icon-upload2"
              status="primary"
              @click="upload1()"
            >导出
            </vxe-button>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <div class="scroll-box">
      <div v-if="seachStatus" class="search">
        <vxe-form
          title-align="right"
          title-width="140"
          title-colon
          :data="searchData"
          @submit="searchEvent"
          @reset="searchReset"
        >
          <vxe-form-item title="选择查询日期" span="6">
            <vxe-input
              v-model="searchData.startSearchTime"
              label-format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="开始日期"
              type="datetime"
              clearable
              multiple
            />
          </vxe-form-item>
          <vxe-form-item span="4">
            <vxe-input
              v-model="searchData.endSearchTime"
              label-format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="结束日期"
              type="datetime"
              clearable
              multiple
            />

          </vxe-form-item>
          <vxe-form-item v-click-outside="oldPersonoutSide" title="老人姓名" span="7">
            <vxe-input
              v-model="searchData.serviceOldPersonName"
              placeholder="请输入姓名"
              @change="oldPersoninputChange"
              @blur="oldPersoninputBlur"
              @focus="oldPersoninputfocus"
            />
            <div v-show="isOpen" class="autocomplete">
              <ul v-for="(name, i) in filteredNameData" v-show="isOpen" :key="i" class="autocomplete-results">
                <li @click="setResult(name)">{{ name }}</li>
                <li @click="deleteOldPersonRecord(name)"><i class="el-icon-close"/></li>
              </ul>
            </div>

          </vxe-form-item>

          <vxe-form-item v-click-outside="workeroutSide" title="工作人员姓名" span="7">
            <vxe-input
              v-model="searchData.serviceWorkPersonName"
              placeholder="请输入工作人员姓名"
              clearable
              @change="workerinputChange"
              @blur="workerinputBlur"
              @focus="workerinputfocus"
            />
            <div v-show="isWorkerOpen" class="autocomplete">
              <ul
                v-for="(name, i) in workPersonNameList"
                v-show="isWorkerOpen"
                :key="i"
                class="autocomplete-results"
              >
                <li @click="setWorkerResult(name)">{{ name }}</li>
                <li @click="deleteWorkPersonRecord(name)"><i class="el-icon-close"/></li>
              </ul>
            </div>
          </vxe-form-item>
          <vxe-form-item title="常住地址" span="24" class="area-form">
            <RegionForm ref="usualTenant" v-model="usualTenant"/>
          </vxe-form-item>
          <vxe-form-item title="人员类别" span="4">
            <vxe-select v-model="searchData.personType" placeholder="请选择人员类别" style="width: 150px">
              <vxe-option
                v-for="item in personCategoryList"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal"
              />
            </vxe-select>
          </vxe-form-item>
          <vxe-form-item title="项目服务" span="6">
            <el-select v-model="searchData.projectService" placeholder="请选择项目服务" filterable="filterable"
                       multiple="multiple" @change="changeProjectService">
              <el-option
                v-for="item in projectServiceList"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal"
              />
            </el-select>
          </vxe-form-item>
          <vxe-form-item title="政府项目服务类别" titleWidth="155" span="6"
                         v-if="searchData.projectService.includes('163_101')">
            <vxe-select v-model="searchData.zfProjectServiceCategory" placeholder="请选择政府项目服务类别"
                        multiple="multiple"
                        style="width: 150px">
              <vxe-option
                v-for="item in zfProjectServiceCategoryList"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal"
              />
            </vxe-select>
          </vxe-form-item>
          <vxe-form-item align="center" span="24">
            <vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
            <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
          </vxe-form-item>
        </vxe-form>
      </div>

      <div class="table">

        <vxe-grid
          ref="xGrid1"
          border
          stripe
          resizable
          auto-resize
          :columns="tableColumn"
          :data="tableData"
          :loading="loading"
          header-align="center"
        >
          <template v-slot:name="{ row }">

            <p v-if="searchData.serviceOldPersonId == ''" class="link-a" @click="goPage('personDetail', row)">{{
                row.serviceOldPersonName
              }}</p>
            <p v-if="searchData.serviceOldPersonId != ''" class="link-a" @click="goPage('personDetail', row)">{{
                row.serviceWorkPersonName
              }}</p>

          </template>
          <template v-slot:telephone="{ row }">
            <p v-if="searchData.serviceOldPersonId == ''">{{ row.serviceOldPersonTel }}</p>

            <p v-if="searchData.serviceOldPersonId != ''">{{ row.serviceWorkPersonTel }}</p>

          </template>
          <template v-slot:serviceWorkPersonName="{ row }">
            <p style="color:#2d8cf0">{{ row.serviceWorkPersonName }}</p>

          </template>
          <template v-slot:time="{ row }">
            <p>{{ row.serviceBeginTime }}</p>
            <p style="color:red">{{ row.serviceEndTime }}</p>
          </template>

          <template v-slot:operation="{ row }">
            <vxe-button v-if="!row.ismore" type="text" status="primary" @click="goPageDetai('xiangqing', row)">详情
            </vxe-button>
            <vxe-button v-if="row.status == '135_1' && !row.ismore" type="text" status="primary" @click="callBack(row)">
              回访
            </vxe-button>
            <vxe-button
              v-if="!row.ismore"
              type="text"
              icon="el-icon-plus"
              size="mini"
              @click="row.ismore = true"
            />
            <!--            v-permission="'ServeRecordsEditButton'"-->
            <vxe-button v-if="row.ismore" type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
            <vxe-button v-if="row.ismore" type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
            <vxe-button
              v-if="row.ismore"
              type="text"
              size="mini"
              icon="el-icon-minus"
              @click="row.ismore = false"
            />
          </template>
        </vxe-grid>
      </div>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        />
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/store/API/api'

import RegionForm from '@/components/RegionForm.vue'
// 提交验证
Vue.directive('clickOutside', {
  // 初始化指令
  bind(el, binding) {
    function clickHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e)
      }
    }

    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = clickHandler
    document.addEventListener('click', clickHandler)
  },
  update() {
  },
  unbind(el) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
})
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  components: {
    RegionForm
  },
  props: {
    // 老人id
    serviceOldPersonIdProp: {
      type: String,
      default: ''
    },
    // 企业id
    serviceOrgIdProp: {
      type: String,
      default: ''
    },
    // 从业人员di
    serviceWorkPersonIdProp: {
      type: String,
      default: ''
    },
    // 是否分租户
    hasTenant: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      seachStatus: false,
      usualTenant: [],
      searchData: {
        serviceOldPersonId: '',
        serviceOldPersonName: '',
        serviceWorkPersonName: '',
        serviceOrgId: '',
        serviceWorkPersonId: '',
        endSearchTime: '',
        startSearchTime: '',
        personType: '',
        projectService: [],
        zfProjectServiceCategory: []
      },
      personCategoryList: [],
      isWorkerOpen: false, // 从业人员搜索历史框
      isOpen: false, // 老人搜索历史框
      arrowCounter: -1,
      filteredNameData: [], // 老人搜索历史
      workPersonNameList: [], // 从业人员搜索历史
      projectServiceList: [],
      zfProjectServiceCategoryList: [],
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 60,
          align: 'center'
        },
        {
          // field: 'serviceOldPersonName',
          title: this.serviceOldPersonIdProp == '' ? '服务对象' : '从业人员',
          // showOverflow: true,
          align: 'center',
          width: "100",
          slots: {
            default: 'name'
          }
        },
        {
          // field: 'serviceOldPersonTel',
          title: this.serviceOldPersonIdProp == '' ? '联系方式' : '从业人员联系方式',
          // showOverflow: true,
          align: 'center',
          width: "140",
          slots: {
            default: 'telephone'
          }
        },
        // {
        // 	// field: 'serviceOldPersonTel',
        // 	title:'工作人员',
        // 	align: "center",
        // 	slots: {
        // 		default: 'serviceWorkPersonName'
        // 	}
        // 	// minWidth: "160"
        // },
        {
          field: 'serviceOrgName',
          title: '服务公司',
          showOverflow: true,
          align: 'center',
          width: "200"
        },
        {
          field: 'serviceItemName',
          title: '服务项目',
          showOverflow: true,
          align: 'left',
          minWidth: "200"
        },
        {
          field: 'statusName',
          title: '状态',
          showOverflow: true,
          align: 'center',
          width: "100"
        },
        {
          field: 'serviceScore',
          title: '满意度',
          showOverflow: true,
          align: 'center',
          width: "80"
        },
        {
          field: 'personTypeNames',
          title: '人员类别',
          showOverflow: true,
          align: 'left',
          width: "120"
        },
        {
          // field: 'time',
          title: '服务时间',
          // showOverflow: true,
          align: 'center',
          width: "210",
          slots: {
            default: 'time'
          }
        },
        {
          title: '操作',
          width: '200',
          fixed: 'right',

          // showOverflow: true,
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: [],
      removeWorkPersonSearchItem: '',
      removeOldPersonSearchItem: ''
    }
  },
  computed: {
    ...mapState([
      'layouts'
    ])

  },
  created() {
    this.searchData.serviceWorkPersonId = this.serviceWorkPersonIdProp
    this.searchData.serviceOrgId = this.serviceOrgIdProp
    this.searchData.serviceOldPersonId = this.serviceOldPersonIdProp

    console.log(this.serviceOldPersonIdProp, this.serviceOldPersonIdProp != '', 'serviceOldPersonIdProp')
    // console.log(typeof(this.serviceOrgIdProp),'serviceOrgIdProp');
    // console.log(typeof(this.serviceWorkPersonIdProp),'serviceWorkPersonIdProp');

    this.initView()
  },
  methods: {
    ...mapActions([
      'queryhomeServiceRecordList',
      // "getServeRecordsListServe",
      'delServeRecordsServe',
      'serchRecord',
      'getSysDictServe',
      'homeServiceRecordExport4',
      'homeServiceRecordExportImg'
    ]),

    changeProjectService() {
      if (!this.searchData.projectService.includes('163_101')) {
        this.searchData.zfProjectServiceCategory = []
      }
    },
    initView() {
      this.getLists()
      this.getDicList()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    getDicList() {
      this.getSysDictList('personCategory', 'personCategoryList') // 人员类别
      this.getSysDictList('projectService', 'projectServiceList') // 项目服务
      this.getSysDictList('zfProjectServiceCategory', 'zfProjectServiceCategoryList') // 政府项目服务类别
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goPage(type, itemData) {
      if (type == 'personDetail') {
        console.log('personDetail')
        this.$router.push({
          name: 'AddPerson',
          params: {
            type: 'detail',
            id: itemData.serviceOldPersonId
          }
        })
      } else {
        this.$router.push({
          name: 'AddServeRecords',
          params: {
            type: type,
            id: type == 'add' ? null : itemData.id
          },
          query: {
            item: type == 'add' ? null : JSON.stringify(itemData),
            serviceOrgId: this.serviceOrgIdProp
          }
        })
      }
    },
    goPageDetai(type, itemData) {
      this.$router.push({
        name: 'SerRecordDetail',
        params: {
          type: type,
          id: type == 'add' ? null : itemData.id
        },
        query: {
          item: type == 'add' ? null : JSON.stringify(itemData)
        }
      })
    },

    callBack(itemData) {
      console.log(itemData)
      this.$router.push({
        name: 'AddRevisit',
        params: {
          id: itemData.id

        },
        query: {
          id: itemData.id,
          revisitStart: itemData.revisitStart,
          revisitDesc: itemData.revisitDesc,
          revisitImgInfoList: JSON.stringify(itemData.revisitImgInfoList)
        }
      })
    },

    getLists() {
      this.loading = true
      this.queryhomeServiceRecordList({

        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        serviceOldPersonId: this.searchData.serviceOldPersonId,
        serviceOldPersonName: this.searchData.serviceOldPersonName,
        serviceWorkPersonName: this.searchData.serviceWorkPersonName,
        serviceOrgId: this.searchData.serviceOrgId,
        serviceWorkPersonId: this.searchData.serviceWorkPersonId,
        startSearchTime: this.searchData.startSearchTime,
        endSearchTime: this.searchData.endSearchTime,
        personType: this.searchData.personType == '' ? null : Array(this.searchData.personType),
        hasTenant: this.hasTenant,
        projectService: this.searchData.projectService,
        zfProjectServiceCategory: this.searchData.zfProjectServiceCategory,
        regionId: this.usualTenant.length > 0 ? this.usualTenant[this.usualTenant.length - 1] : ''
      }).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.records

          this.tablePage.totalResult = +res.data.total
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], 'ismore', false)
          }
          // this.tableData.map(item=>{
          // 	item.ismore = 'false'
          // })
          console.log(this.tableData)
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {
        serviceOldPersonId: '',
        serviceOldPersonName: '',
        serviceWorkPersonName: '',
        serviceOrgId: '',
        serviceWorkPersonId: '',
        endSearchTime: '',
        startSearchTime: '',
        personType: ''
      }
      this.usualTenant = []
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delServeRecordsServe({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success'
          })
          this.getLists()
        }
        this.loading = false
      })
    },
    // 输入框改变
    oldPersoninputChange(value) {
      this.isOpen = true
    },
    // 输入框失去焦点
    oldPersoninputBlur() {
      // this.isOpen = false
    },
    // 输入框聚焦
    oldPersoninputfocus() {
      this.querySearchRecord()
      this.isOpen = true
    },
    // 点击历史记录
    setResult(value) {
      this.searchData.serviceOldPersonName = value
      this.isOpen = false
    },
    setWorkerResult(value) {
      this.searchData.serviceWorkPersonName = value
      this.isWorkerOpen = false
    },
    // 输入框搜索记录
    querySearchRecord() {
      const params = {
        removeWorkPersonSearchItem: this.removeWorkPersonSearchItem,
        removeOldPersonSearchItem: this.removeOldPersonSearchItem
      }
      this.serchRecord(params).then(res => {
        this.filteredNameData = res.data.oldPersonNameList
        this.workPersonNameList = res.data.workPersonNameList
      })
    },
    // 导出
    upload() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.homeServiceRecordExport
      const myObj = {
        method: 'post',
        url,
        fileName: '服务记录',
        params: {
          pageNum: this.tablePage.currentPage,
          pageSize: this.tablePage.pageSize,
          serviceOldPersonId: this.searchData.serviceOldPersonId,
          serviceOldPersonName: this.searchData.serviceOldPersonName,
          serviceWorkPersonName: this.searchData.serviceWorkPersonName,
          serviceOrgId: this.searchData.serviceOrgId,
          serviceWorkPersonId: this.searchData.serviceWorkPersonId,
          startSearchTime: this.searchData.startSearchTime,
          endSearchTime: this.searchData.endSearchTime,
          personType: this.searchData.personType == '' ? null : Array(this.searchData.personType),
          hasTenant: this.hasTenant,
          regionId: this.usualTenant.length > 0 ? this.usualTenant[this.usualTenant.length - 1] : '',
          projectService: this.searchData.projectService,
          zfProjectServiceCategory: this.searchData.zfProjectServiceCategory,
        }
      }
      this.$tools.exportMethod(myObj)
    },
    // 导出
    upload1() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.homeServiceRecordExport
      const myObj = {
        method: 'post',
        url,
        fileName: '服务记录',
        params: {
          pageNum: this.tablePage.currentPage,
          pageSize: this.tablePage.pageSize,
          serviceOldPersonId: this.searchData.serviceOldPersonId,
          serviceOldPersonName: this.searchData.serviceOldPersonName,
          serviceWorkPersonName: this.searchData.serviceWorkPersonName,
          serviceOrgId: this.searchData.serviceOrgId,
          serviceWorkPersonId: this.searchData.serviceWorkPersonId,
          startSearchTime: this.searchData.startSearchTime,
          endSearchTime: this.searchData.endSearchTime,
          personType: this.searchData.personType == '' ? null : Array(this.searchData.personType),
          hasTenant: this.hasTenant,
          regionId: this.usualTenant.length > 0 ? this.usualTenant[this.usualTenant.length - 1] : '',
          projectService: this.searchData.projectService,
          zfProjectServiceCategory: this.searchData.zfProjectServiceCategory,
        }
      }
      this.homeServiceRecordExport4(myObj.params).then(res => {
        if (res.code == 200) {
          window.open(res.data)
        }
      })
    },
    // 导出
    upload2() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.homeServiceRecordExport
      const myObj = {
        method: 'post',
        url,
        fileName: '服务记录',
        params: {
          pageNum: this.tablePage.currentPage,
          pageSize: this.tablePage.pageSize,
          serviceOldPersonId: this.searchData.serviceOldPersonId,
          serviceOldPersonName: this.searchData.serviceOldPersonName,
          serviceWorkPersonName: this.searchData.serviceWorkPersonName,
          serviceOrgId: this.searchData.serviceOrgId,
          serviceWorkPersonId: this.searchData.serviceWorkPersonId,
          startSearchTime: this.searchData.startSearchTime,
          endSearchTime: this.searchData.endSearchTime,
          personType: this.searchData.personType == '' ? null : Array(this.searchData.personType),
          hasTenant: this.hasTenant,
          regionId: this.usualTenant.length > 0 ? this.usualTenant[this.usualTenant.length - 1] : '',
          projectService: this.searchData.projectService,
          zfProjectServiceCategory: this.searchData.zfProjectServiceCategory,
        }
      }
      this.homeServiceRecordExportImg(myObj.params).then(res => {
        if (res.code == 200) {
          window.open(res.data)
        }
      })
    },
    // 统计导出
    dataupload() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += '/homeServiceRecord/report-export'
      const myObj = {
        method: 'post',
        url,
        fileName: '服务记录报表导出',
        params: {
          pageNum: this.tablePage.currentPage,
          pageSize: this.tablePage.pageSize,
          serviceOldPersonId: this.searchData.serviceOldPersonId,
          serviceOldPersonName: this.searchData.serviceOldPersonName,
          serviceWorkPersonName: this.searchData.serviceWorkPersonName,
          serviceOrgId: this.searchData.serviceOrgId,
          serviceWorkPersonId: this.searchData.serviceWorkPersonId,
          startSearchTime: this.searchData.startSearchTime,
          endSearchTime: this.searchData.endSearchTime,
          personType: this.searchData.personType == '' ? null : Array(this.searchData.personType),
          projectService: this.searchData.projectService,
          zfProjectServiceCategory: this.searchData.zfProjectServiceCategory,
          regionId: this.usualTenant.length > 0 ? this.usualTenant[this.usualTenant.length - 1] : '',
          hasTenant: this.hasTenant
        }
      }
      this.$tools.exportMethod(myObj)
    },
    // 点击div外部触发指令
    oldPersonoutSide() {
      this.isOpen = false
    },
    workeroutSide() {
      this.isWorkerOpen = false
    },
    // 工作人员input改变
    workerinputChange() {
      this.isWorkerOpen = true
    },
    workerinputBlur() {
      this.isWorkerOpen = true
    },
    workerinputfocus() {
      this.querySearchRecord()
      this.isWorkerOpen = true
    },
    // 删除单条老人搜索记录
    deleteOldPersonRecord(value) {
      this.removeOldPersonSearchItem = value
      this.querySearchRecord()
    },
    // 删除工作人员搜索记录单条
    deleteWorkPersonRecord(value) {
      this.removeWorkPersonSearchItem = value
      this.querySearchRecord()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .scroll-box {
    flex: 1;
    overflow: auto;
  }

  .datetime {
    display: flex;
    align-items: center;

    span {
      margin: 0 3px;
    }
  }
}

.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.vxe-button.type--button {
  border: 1px solid #f2f4ff9e !important;
}

.autocomplete-results {
  display: flex;
  justify-content: space-between;
}

.autocomplete {
  position: absolute;
  width: 100%;
  z-index: 200;
  background-color: #fff;
  border: solid 1px;
  border-top: none;
}

.autocomplete-results li :hover {
  cursor: pointer;
}
</style>
